import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { StyledLi } from 'components/styled-li';
import { CONSTANTS } from 'constants/styles/constants';

const STextWrapper = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &:last-child {
        margin-bottom: 1rem;
    }
`;

const STitle = styled(HeaderThird)`
    font-weight: bold;
`;

const SContent = styled.div`
    flex: 0.7;
    line-height: 1.8;

    ${CONSTANTS.MEDIA.max1024`
        flex: 1;
    `}
`;

const SList = styled.ul`
    margin-top: 0.5rem;
    list-style-type: ${({ listStyle }) => listStyle ?? 'decimal-leading-zero'}};
`;

const SBulletListElement = styled(StyledLi)`
    margin-left: 3.5rem;
`;

const SListElement = styled.li`
    margin: 0.5rem 0 0.5rem 2rem;
    font-size: 1.1rem;
    font-weight: 300;

    &:first-child {
        margin-top: 0;
    }
`;

const SCompanyDetails = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 600;
`;
export const Content = () => {
    const renderNestedListElements = (
        listItemsCount,
        sectionKey,
        isBulletList,
    ) => {
        return Array.from({ length: listItemsCount }).map((_, index) => {
            const message = (
                <FormattedMessage
                    id={`view-privacy-policy.${sectionKey}${index + 1}`}
                />
            );

            if (isBulletList) {
                return (
                    <SBulletListElement key={index}>
                        {message}
                    </SBulletListElement>
                );
            }

            return <SListElement key={index}>{message}</SListElement>;
        });
    };

    return (
        <SContent>
            <STextWrapper>
                <FormattedMessage id="view-privacy-policy.description" />
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section1Header" />
                </STitle>
                <SList listStyle="decimal">
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section1Text1" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section1Text2" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section1Text3" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section1Text4" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section1Text5" />
                    </SListElement>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section2Header" />
                </STitle>
                <SList listStyle="decimal">
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section2Text1" />
                        <SList>
                            {renderNestedListElements(
                                7,
                                'section2Text1Point',
                                true,
                            )}
                        </SList>
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section2Text2" />
                        <SList>
                            {renderNestedListElements(
                                15,
                                'section2Text2Point',
                                true,
                            )}
                        </SList>
                    </SListElement>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section3Header" />
                </STitle>
                <SList listStyle="decimal">
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section3Text1" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section3Text2" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section3Text3" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section3Text4" />
                    </SListElement>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section4Header" />
                </STitle>
                <SList listStyle="decimal">
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section4Text1" />
                    </SListElement>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section5Header" />
                </STitle>
                <SList listStyle="decimal">
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section5Text1" />
                    </SListElement>
                    <SList listStyle="lower-alpha">
                        {renderNestedListElements(
                            4,
                            'section5Text1Point',
                            true,
                        )}
                    </SList>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section6Header" />
                </STitle>
                <SList listStyle="decimal">
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text1" />
                        <SList listStyle="lower-alpha">
                            <SListElement>
                                <FormattedMessage id="view-privacy-policy.section6Text1Point1" />
                            </SListElement>
                        </SList>
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text2" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text3" />
                        <SList listStyle="lower-alpha">
                            {renderNestedListElements(5, 'section6Text3Point')}
                        </SList>
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text4" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text5" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text6" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text7" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text8" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section6Text9" />
                    </SListElement>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <SList listStyle="decimal">
                    <STitle>
                        <FormattedMessage id="view-privacy-policy.section7Header" />
                    </STitle>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section7Text1" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section7Text2" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section7Text3" />
                        <SList listStyle="lower-alpha">
                            {renderNestedListElements(2, 'section7Text3Point')}
                        </SList>
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section7Text5" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section7Text6" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section7Text7" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section7Text8" />
                        <SList>
                            {renderNestedListElements(
                                7,
                                'section7Text8Point',
                                true,
                            )}
                        </SList>
                    </SListElement>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section8Header" />
                </STitle>
                <SList listStyle="decimal">
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section8Text1" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section8Text2" />
                    </SListElement>
                    <SListElement>
                        <FormattedMessage id="view-privacy-policy.section8Text3" />
                        <FormattedMessage id="view-privacy-policy.section8Text4" />
                        <SList listStyle="lower-alpha">
                            {renderNestedListElements(3, 'section8Text4Point')}
                        </SList>
                    </SListElement>
                </SList>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section9Header" />
                </STitle>
                <Paragraph>
                    <FormattedMessage id="view-privacy-policy.section9Text1" />
                </Paragraph>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section10Header" />
                </STitle>
                <Paragraph>
                    <FormattedMessage id="view-privacy-policy.section10Text1" />
                </Paragraph>
            </STextWrapper>
            <STextWrapper>
                <STitle>
                    <FormattedMessage id="view-privacy-policy.section11Header" />
                </STitle>
                <Paragraph>
                    <FormattedMessage id="view-privacy-policy.section11Text1" />
                </Paragraph>
                <SCompanyDetails>
                    <FormattedMessage id="view-privacy-policy.section11Text2" />
                    <FormattedMessage id="view-privacy-policy.section11Text3" />
                    <FormattedMessage id="view-privacy-policy.section11Text4" />
                    <FormattedMessage id="view-privacy-policy.section11Text5" />
                    <FormattedMessage id="view-privacy-policy.section11Text6" />
                </SCompanyDetails>
            </STextWrapper>
        </SContent>
    );
};
